import React from 'react';
import './App.css';
import Book from './Book.js'
import SearchBar from './SearchBar.js'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import ListGroup from 'react-bootstrap/ListGroup'
import Spinner from 'react-bootstrap/Spinner'
import Image from 'react-bootstrap/Image'
import NewWindow from 'react-new-window'
import queryString from 'query-string'
import { Document } from 'react-pdf';
import { Page } from 'react-pdf';
import {pdfjs} from 'react-pdf'
//for typeahead search suggestions

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const API_HOST = process.env.REACT_APP_API_HOST; //example, localhost
export const API_URLBASE = API_HOST;


class PDFWindow extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			terms: props.terms
		}

		this.customTextRenderer = this.customTextRenderer.bind(this);
	}

	customTextRenderer(textItem) {
		for(let i = 0; i < this.state.terms.length; i++) {
			let term = this.state.terms[i];
			if(textItem.str.includes(term)) {
				return textItem.str
				.split(term)
				.reduce((strArray, currentValue, currentIndex) => (
					currentIndex === 0
					? ([...strArray, currentValue])
					: ([...strArray, (
											// eslint-disable-next-line react/no-array-index-key
											<mark key={currentIndex} className="pdf-mark">
											{term}
											</mark>
											), currentValue])
					), []);
			}
		}
		return textItem.str;
	}

	render() {
		let string = queryString.stringify({book_id: this.props.book_id,
			start_page: this.props.start_page,
			end_page: this.props.end_page,
			terms: this.props.terms
		}, {arrayFormat: 'index'})
		
		return(
			<NewWindow 
			url={"/page?"+ string}
			copyStyles={true} 
			features={{width: 1000, height: 1200}}
			>
			<Document style={{display: "flex", "justify-content": "center"}} file={this.props.pdfURL} >
			<Page   renderMode="canvas" scale={1.5} pageNumber={1} 
			customTextRenderer={(textItem) => this.customTextRenderer(textItem)} 
			/>
			</Document>
			</NewWindow>	
			);
	}
}


/** 
	This returns the selection used to search only particular books in the corpus
*/
function SelectBooks(props) {
	
	
	if(props.showSelectBooks) {
		const checkboxes = props.books.map((book) =>(
			<ListGroup.Item key={book.id}>
			
			<Form.Check type="checkbox" checked={props.selectedBooks[book.id]} onChange={(evt) => props.handleBookSelect(evt,book.id)} label={book.title}/>
			</ListGroup.Item>
			));
		return(

			<Card>
			<Card.Header>Select Books to Search</Card.Header>
			<ListGroup>
			{checkboxes}
			<ListGroup.Item key="book-select-all"><Button id="book-select-all" onClick={props.selectAllBooks}variant="outline-secondary">Select All...</Button><Button id="book-remove-all" variant="outline-secondary" onClick={props.removeAllBooks}>Remove All...</Button></ListGroup.Item>

			</ListGroup>
			</Card>

			);
	} else {
		return(<div></div>)
	}
	
}

/*
 * Return a <Card> with the Help text. 
 */

function Help(props) {
	if(props.showHelp) {
		return(
			<Card>
			<Card.Header>These types of queries are possible</Card.Header>
			<ListGroup variant="flush">	
			<ListGroup.Item><b>active mentation</b> (search for pages that contain the phrase &ldquo;active mentation&rdquo;)</ListGroup.Item>		
			<ListGroup.Item><b>active + mentation</b> (search for pages that contain the word &ldquo;active&rdquo; and &ldquo;mentation&rdquo;)</ListGroup.Item>
			<ListGroup.Item><b>active | mentation</b> (search for pages that contain the word &ldquo;active&rdquo; or the word &ldquo;mentation&rdquo;)</ListGroup.Item>
			<ListGroup.Item><b>-active mentation</b> (search for pages that contain the word &ldquo;mentation&rdquo; but do not contain the word &ldquo;active&rdquo;)</ListGroup.Item>
			</ListGroup>
			<Card.Footer>You may also limit your search to specific books by clicking the <em>&ldquo;Select Books&rdquo;</em> button and choosing which books you'd like to search.</Card.Footer>
			</Card>
			);
	} else {
		return( <div></div>);
	}
}

/*
 * Dislay information about forum topics on this search term
 * Props:
 *  - loading
 *  - forumInfo
 * Example Info:
 * {
    "forum_permalink": "https://buzzell.alexpoulos.com/wordpress/forums/forum/index-terms/",
    "topic_count": 1,
    "topics": [
        {
            "id": "25",
            "parent_id": 5,
            "parent_permalink": "https://buzzell.alexpoulos.com/wordpress/forums/forum/index-terms/",
            "permalink": "https://buzzell.alexpoulos.com/wordpress/forums/topic/acid/",
            "title": "Acid"
        }
    ]
}

 * Logic:
 * - if it exists, offer to take them there;
   - if it doesn't, offer to take them to the forum (or a create page)
 */
function ForumConnection(props) {
	let cardBody = []
	if(!props.forumInfo) {
		if(!props.loading) return(<div></div>);
	} else {
		let forumInfo = props.forumInfo
		if(forumInfo.topic_count < 0) {
			cardBody.push("There was an error searching the forum. If the problem persists, please send an e-mail to admin@...")
		} else if(forumInfo.topic_count === 0) {
			
			cardBody.push(
					<ListGroup variant="flush">
					<ListGroup.Item key={"no-topic-0"}>No discussion topic yet exists for this search term. Get the discussion started by visiting the forum <a href={forumInfo.forum_permalink + "/#new-post"}>here</a>.</ListGroup.Item>
					</ListGroup>
				);
		} else if(forumInfo.topic_count >= 1) {
			let topics = []
			forumInfo.topics.forEach((topic) => {
				topics.push(
					<ListGroup.Item key={topic.id}>Join the discussion on this topic by visiting: <a href={topic.permalink}>{topic.title}</a>.</ListGroup.Item>
				);
			});

			cardBody.push(
				
				<div>
				
				<ListGroup variant="flush">
				
				{topics}
				</ListGroup>
				</div>
			);
			
		}
	}
	return(
		<Card>
			<Card.Header>Forum Results</Card.Header>
			
				<Spinner animation="border" style={{display: (props.loading) ? 'block' : 'none', marginLeft: "50%", marginTop: "4px"}} sz="lg"/>
				<Card.Body>{cardBody}</Card.Body>
			
		</Card>
	);
}



/*
 * The root app, most logic is here
 */
class App extends React.Component {
	constructor(props) {
		super(props);

		this.resultClicked = this.resultClicked.bind(this);
		// this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
		// this.onPageLoadSuccess = this.onPageLoadSuccess.bind(this);

		//this.customTextRenderer = this.customTextRenderer.bind(this);

		this.state = {
			query: '',
			showPDF: false,
			booksLoading: false,
			topicsLoading: false,
			suggestions: [{id: 0, term: "", sub_entries: []}],
			suggestionSelected: null,
			books: [],
			selectedBooks: {},
			showSelectBooks: false,
			results: [],
			windows: []
		}

		this.terms = []
	}


	//when a search result is clicked, open its corresponding pdf
	resultClicked(bookId, page) {
		let start_page = parseInt(page.page_number, 10) -1
		let end_page = parseInt(page.page_number, 10) + 1
		var windowArray = this.state.windows.concat({book_id: bookId, start_page: start_page, end_page: end_page, terms: this.terms});
		this.setState({
			windows: windowArray 
		});
	}


	//On enter, launch a search
	handleKeyDown(evt) {
		if(evt.key === 'Enter') {
			this.setState({query: evt.target.value, showSelectBooks: false})
			const searchQuery = evt.target.value
			this.performQuery(searchQuery)
		}
	}

	//Elasticsearch returns a hit that looks something like this:
	//... This is some <mark>text</mark> that you <mark>searched</mark> for.
	//In this case, we searched for "text search", vel sim.
	//This creates list sorted by length (descending) of all the search terms that elasticsearch
	//returned for the given query. We use this to highlight the corresponding terms when the PDF is displayed
	// example return value: ["searched", "text"]
	extractSearchTerms(data) {
		let searchTerms = {};
		data.forEach( (book) => {
			book.pages.forEach((page) => {
				page.context.forEach((context) => {
					let left_offset = context.indexOf("<mark>") + 6;
					let right_offset = context.indexOf("</mark>")
					let term = context.substring(left_offset,right_offset).trim().replace(/\s+/, ' ');
					searchTerms[term] = 1;
				});
			});
		});
		//sort in descending order
		this.terms = Object.keys(searchTerms).sort((a,b) => {
			if(a.length < b.length) {
				return 1;
			} else if(a.length > b.length) {
				return -1;
			}
			return 0;
		});
	}

	performQuery(searchQuery) {
		const axios = require('axios')
		let selectedBookArray = []
		Object.keys(this.state.selectedBooks).forEach((book_id) => {
			if(this.state.selectedBooks[book_id]) {
				selectedBookArray.push(parseInt(book_id,10))
			}
		});
		this.setState({booksLoading: true, forumsLoading: true})

		axios.post(API_URLBASE + "/books/search/", {query: searchQuery, book_ids: selectedBookArray})
		.then(
			(result) => {
				this.extractSearchTerms(result.data)
				this.setState({
					booksLoading: false,
					results: result.data
				});
			}
			)
		.catch((error) => {
			console.error("error: ", error);
			this.setState({
					booksLoading: false,
					results: []
				});
		})

		//TODO, don't hardcode the forum_id
		axios.post(API_URLBASE + "/wordpress/wp-json/pdf-searchall/v1/topic_exists",{
			forum_id: 5,
			search_term: searchQuery
		})
		.then((result) => {
			console.log(result)
			this.setState({
				forumInfo: result.data,
				forumsLoading: false
			})
		})
	}

	//After the page loads:
	// - grab the books (to create the select)
	// - grab the suggestions
	// TODO: cache suggestions in local storage
	componentDidMount() {
		const axios = require('axios')
		axios.get(API_URLBASE + "/books/")		
		.then((result) => {
			let results = result.data
			let allBooks = {}
			results.forEach((book) => (allBooks[book.id] = true));
			this.setState({
				books: result.data,
				selectedBooks: allBooks
			})

		})
		.catch((error) => {console.error(error)})

		axios.get(API_URLBASE + "/books/search/suggestions/")		
		.then((result) => {
			let suggestions = result.data
			
			this.setState({
				suggestions: suggestions
			});
		})
		.catch((error) => {console.error(error)})
	}
	
	//render the app
	render() {
		const toggleSelectBooks = (evt) => {
			this.setState((prevState) => ({showSelectBooks: !prevState.showSelectBooks}));
		}
		

		const bookItems = this.state.results.map((book) =>
			<Row key={book.id} style={{marginBottom: '20px', marginTop: '20px'}}>
			<Col lg={2} sm={12} style={{marginBottom: '10px', textAlign: 'center'}}>
			<a href={book.info_url} target="_blank" rel="noopener noreferrer"><Image src={book.thumbnail_url} /></a>
			</Col>
			<Col lg={10} >
			<Book key={book.id} page_offset={book.page_offset} id={book.id} year={book.year} author={book.author}
			pages={book.pages} title={book.title} thumbnail={book.thumbnail_url} info_url={book.info_url}
			terms={this.terms} resultClicked={(id,page) => this.resultClicked(id,page) }/>
			</Col>
			</Row>
			);

		const popupWindows = this.state.windows.map((win) => 
			<PDFWindow key={win.book_id.toString() + "-" + win.start_page.toString()} book_id={win.book_id} start_page={win.start_page} end_page={win.end_page} terms={win.terms} pdfURL={win.pdfURL} />
			);

		return (
			<>
			<Container style={{marginTop: "50px"}}>
			<Row>
			<Col lg={12}>
				<SearchBar
				onKeyDown={(evt) => this.handleKeyDown(evt)} 
				onHelp={(evt) => {
					this.setState((prevState) => ({showHelp: !prevState.showHelp}))
				}}
				onSearch={(query) => {this.performQuery(query)}}
				onChange={(selected) => {this.setState({suggestionSelected: selected})}}
				selected={this.state.suggestionSelected}
				suggestions={this.state.suggestions}
				onSelectBooks={(evt) => {toggleSelectBooks(evt)}}
			/>
			<Help showHelp={this.state.showHelp} />
			<SelectBooks showSelectBooks={this.state.showSelectBooks} books={this.state.books} selectedBooks={this.state.selectedBooks} 
			handleBookSelect={(evt,key) => {
				this.setState((prevState) => {
					let updatedBooks = prevState.selectedBooks
					updatedBooks[key] = !prevState.selectedBooks[key]
					return {selectedBooks: updatedBooks}
				})
				}}
				selectAllBooks={() => {
					this.setState((prevState) => {
						let updatedBooks = prevState.selectedBooks

						for(let key in updatedBooks) {updatedBooks[key] = true}
						return {selectedBooks: updatedBooks}
					})
				}}
				removeAllBooks={() => {
					this.setState((prevState) => {
						let updatedBooks = prevState.selectedBooks
						for(let key in updatedBooks) {updatedBooks[key] = false}
						return {selectedBooks: updatedBooks}
					})
				}
				}/>
			</Col>
			</Row>
			<Row>
			<Col lg={{span: 10, offset: 2}} style={{alignText: 'center'}}>
				<ForumConnection loading={this.state.forumsLoading} forumInfo={this.state.forumInfo} />
			</Col>
			</Row>
			<Row>
			<Col lg={{span: 10, offset: 2}} style={{alignText: 'center'}}>
				<Spinner animation="border" style={{display: (this.state.booksLoading) ? 'block' : 'none', marginLeft: "50%", marginTop: "100px"}} sz="lg"/>
			</Col>
			</Row>
			
			{bookItems}
			{popupWindows}
			</Container>

			</>



			);
		}
	}




export default App;

import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import App from './App';
import PDFPage from './Page';

export const BASE = "/";

function AppRouter() {
	console.log(BASE)
	return(
		<Router>
			<Route path={BASE} exact component={App} />
			<Route path={BASE+"page"} component={PDFPage} />

		</Router>

	);
}

export default AppRouter;